<template>
  <div>
    <b-card>
      <div>
        <validation-observer
          ref="validator"
          mode="eager"
        >
          <b-form>
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="form.name"
                      :state="errors.length ? false : null"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Role -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Admin Role"
                  label-for="admin-role"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="admin-role"
                    rules="required"
                  >
                    <v-select
                      v-model="form.roleId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="val => val.id"
                      input-id="admin-role"
                      label="name"
                      :options="roles"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="save"
            >
              Update admin
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-card>

    <ChangeAdminUserPassword :user-id="Number(adminId)" />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

import vSelect from 'vue-select'
import ChangeAdminUserPassword from '@/views/apps/create-admin/containers/ChangeAdminUserPassword.vue'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
// eslint-disable-next-line
import { required, password } from '@validations'

export default {
  name: 'CreateAdminCreate',
  components: {
    ChangeAdminUserPassword,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      adminId: null,
      form: {
        name: '',
        roleId: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      roles: 'roles/roles',
      admin: 'auth/admin',
    }),
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchRoles: 'roles/fetchRoles',
      updateAdminUser: 'adminUser/updateAdminUser',
      fetchAdminUserById: 'adminUser/fetchAdminUserById',
      updateAdminUserPassword: 'adminUser/updateAdminUserPassword',
      fetchProfile: 'auth/fetchProfile',
    }),

    initState() {
      this.registerValidator(this.$refs.validator)
      this.fetchRoles()

      if (!this.$route.params.id) return

      this.adminId = this.$route.params.id
      this.fetchAdminUserById(this.adminId)
        .then(response => {
          const { name, roles } = response.data

          this.form.name = name
          this.form.roleId = roles?.at(0)?.id
        })
        .catch(this.checkErrors)
      this.waitRequest(() => this.fetchProfile()).catch(this.checkErrors)
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid) return

      this.update()
    },

    update() {
      this.waitRequest(() => this.updateAdminUser({ id: this.adminId, form: this.form })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Admin is updated',
              icon: 'UserPlusIcon',
              variant: 'info',
            },
          })
          this.$router.push('/create-admin')
        })
        .catch(this.checkErrors))
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
