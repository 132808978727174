<template>
  <b-card>
    <div>
      <validation-observer mode="eager" ref="validator">
        <b-form>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Password" label-for="password">
                <validation-provider #default="{ errors }" name="password" rules="required|password">
                  <b-form-input id="password" v-model="password" :state="errors.length ? false : null" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
            Change password
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

// eslint-disable-next-line
import { required, password } from '@validations'
export default {
  name: 'ChangeAdminUserPassword',
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      password: '',
    }
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      updateAdminUserPassword: 'adminUser/updateAdminUserPassword',
    }),

    initState() {
      this.registerValidator(this.$refs.validator)
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid) return

      this.update()
    },

    update() {
      this.waitRequest(() => {
        return this.updateAdminUserPassword({ id: this.userId, password: this.password })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Password changed',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
          })
          .catch(this.checkErrors)
      })
    },
  },
}
</script>
